h1 {
	text-align: center;
	align-items: center;
	justify-content: center;
}

h3 {
	display: block;
	text-align: center;
	justify-content: center;
}

.instructions {
	justify-content: center;
	align-items: center;
	margin: 2rem 1rem 1rem 1rem;
}

.btn :disabled:hover {
	cursor: not-allowed !important;
}

.custom-shadow {
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
}